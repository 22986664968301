
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { _getURL } from "@/api/index";
import _ from 'lodash';
import { FirstTimeLoginModal } from '@/components/modal';
import { LoadingOverlay, TextInput } from '@mantine/core';
import { CustomLink } from '@/components/app/customLink';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { pageLink, cookieExpireMaxAge } from '@/config/site';
import { toast } from "@/libraries/swal";
import { _postURL } from "@/api/index";
import { setCookie } from 'cookies-next';

export default function Index(props) {
    const { t } = props
    const [countdown, setCountdown] = useState(t('request_otp'))
    const router = useRouter();
    const [visible, setVisible] = useState(false);
    const [opened2, setOpened2] = useState(false)
    const [otpDisable, setOtpDisable] = useState(false)

    const { getInputProps, onSubmit, isValid, values, setFieldError, reset, setFieldValue, clearFieldError } = useForm({
        validateInputOnChange: true,
        initialValues: {
            phoneNumber: '',
            phoneOTP: '',
        },
        validate: {
            phoneNumber: (inputValue) => inputValue.length <= 0 
                ? t('phone_number_cannot_be_empty') 
                : !(/^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/.test(inputValue)) ? t('only_malaysia_phone_number_is_allowed') : null,
            phoneOTP: (inputValue) => inputValue.length !== 6 && t('otp_must_be_6_digits'),
        }
    });

    useEffect(() => {
        if(countdown >= 0){
            const intervalId = setInterval(() => {
                if(countdown != 0){
                    setCountdown(countdown - 1);
                }
                if(countdown == 0){
                    setCountdown(t('resend_otp'));
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [countdown]);

    const _sendOTP = async() => {
        if(values?.phoneNumber){
            let phone_number = values.phoneNumber;
            try {
                let req = {
                    "phone_number": phone_number
                }
                const response = await _postURL("/api/auth/sendOTP", {...req});
                if(!_.isEmpty(response)){
                    setCountdown(60);
                    toast.success(response.message, true);
                }
            } catch (error) {
                toast.error(error)
            }
        }
        else{
            toast.error(t('phone_number_cannot_be_empty'));
        }
    }

    const _checkOTP = async() => {
        // OTP length Check
        if(values?.phoneOTP?.length == 6){
            let phone_number = values.phoneNumber;
            let phone_otp = values.phoneOTP;
            try {
                let req = {
                    "phone_number": phone_number,
                    "phone_otp": phone_otp
                }
                const response = await _postURL("/api/auth/checkOTP", {...req});
                if(!_.isEmpty(response)){
                    if(!response.data){
                        setFieldError('phoneOTP', t('otp_incorrect'));
                    } else {
                        setOtpDisable(true)
                    }
                }
            } catch (error) {
                toast.error(error)
            }
        }
    }

    const _login = async(formData) => {
        try {
            setVisible(true);
            let req = {
                "phone_number": formData.phoneNumber,
                "phone_otp": formData.phoneOTP
            }
            const response = await _postURL("/api/auth/login", {...req});

            if (!_.isObjectLike(response)) throw t("something_unexpected_happen_please_try_again_later")
            if (_.isEmpty(response.data)) throw t("something_unexpected_happen_please_try_again_later")

            if(response?.data?.is_sign_up == true){
                setOpened2(true);
            } else if (!_.isEmpty(response.data?.user_id)) {
                setCookie('user', JSON.stringify(response.data), {maxAge: cookieExpireMaxAge});
                toast.success(t('login_successfully'));
                router.push('/profile')
                return
            }
        } catch (error) {
            toast.error(error)
        } finally {
            setVisible(false);
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'black',
                zIndex: 200,
            }}
        >
            <div className="modal_loginBody_close">
                <div 
                    className="modal_loginBody_closeIcon"
                    onClick={async () => {
                        if (typeof window !== 'undefined' && +window?.history?.state?.idx > 0) {
                            router.back()
                        } else {
                            router.push("/")
                        }
                    }}
                >
                    <img className="w-100" src="/assets/images/close.svg"/>
                </div>
            </div>
            <div className="modal_loginBody_section">
                <img className="w-100" src="/assets/images/login_car.png"/>
                <div className="modal_loginBody_contantContainer">
                    <LoadingOverlay visible={visible} overlayBlur={2} />
                    <form onSubmit={onSubmit((values) => _login(values))} className="modal_loginBody_contantContainerInner">
                        <div>
                            <div className="modal_loginBody_contantLogo">
                                <img className="w-100" src="/assets/images/logo.png"/>
                            </div>
                            <p className="fontSize_20 fontWeight_extraBold modal_loginBody_contantText">
                                {t('signup_login')}
                            </p>
                            <div className="modal_loginBody_contantTextInputMargin">
                                <TextInput 
                                    id='phone_number'
                                    placeholder={t("phone_number_placeholder")}
                                    classNames={{
                                        input: "fontSize_14 textInput",
                                        error: "fontSize_12 fontColor_red textInputError"
                                    }}
                                    type={"number"}
                                    {...getInputProps('phoneNumber')}
                                    autoFocus={false}
                                    
                                />
                            </div>
                            <div className="modal_loginBody_contantTextInputMargin">
                                <TextInput 
                                    id='otp_code'
                                    disabled={otpDisable}
                                    rightSection={
                                        <button 
                                            type="button"
                                            className="fontSize_14 fontWeight_medium fontColor_redHover textInputRequestOPT"
                                            disabled={countdown >= 0 ? true : false}
                                            onClick={()=>{_sendOTP()}}
                                        >
                                            {countdown}
                                        </button>
                                    }
                                    onKeyUp={()=>{_checkOTP()}}
                                    rightSectionWidth={"11.6rem"}
                                    placeholder={t("enter_otp_placeholder")}
                                    classNames={{
                                        input: "fontSize_14 textInput",
                                        error: "fontSize_12 fontColor_red textInputError"
                                    }}
                                    maxLength={6}
                                    {...getInputProps('phoneOTP')}
                                />
                            </div>
                        </div>
                        <button type="submit" disabled={isValid() ? false : true} className="submitLoginButton primaryButtonBackground">
                            <p className="fontSize_18 fontWeight_bold fontColor_white">
                                {t('continue')}
                            </p>
                        </button>
                    </form>
                </div>
                <div className="modal_loginBottom">
                    <p className="fontSize_12 fontColor_grey modal_loginBottom_text">
                        {t('by_clicking_log_in_button_you_agree_to_our')}
                        <CustomLink href={pageLink.general.privacyPolicy}>
                            <span className="fontSize_12 fontWeight_bold fontColor_redHover modal_loginBottom_textRed cursorPointer">{t('privacy_policy')}</span>
                        </CustomLink>.
                    </p>
                </div>
            </div>
            <FirstTimeLoginModal
                opened={opened2}
                setOpened={setOpened2}
                t={t}
                prevFormData={values}
            />
        </div>
    )
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
        revalidate: 5
    };
}